import React, {Component} from 'react';
import moment from 'moment';
import $ from 'jquery';
import trans from '../../translations';
import Modelizer from '../../../vendor/Ry/Core/Modelizer';

class List extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            date : moment(this.models('props.data.filter.date')).format('YYYY-MM-DD')
        }
        this.datepicker = React.createRef()
        this.form = React.createRef()
    }

    componentDidMount() {
        const opts = {
            //zIndexOffset : 100,
            language : 'fr',
            autoclose : true
        }
        const dp = $(this.datepicker.current).datepicker(opts)
        dp.on("changeDate", ()=>{
            this.setState({
                date: moment(dp.datepicker('getDate')).format('YYYY-MM-DD')
            }, ()=>{
                $(this.form.current).submit()
            })
        });
    }

    render() {
        return <div className='col-md-12 vol-container'>
            <form action="" method='get' ref={this.form}>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className='form-group mb-0'>
                            <select className='form-control' name="airline_id" defaultValue={this.models('props.data.filter.airline_id')} onChange={()=>$(this.form.current).submit()}>
                                {this.props.data.select_airlines.map(airline=><option key={airline.id} value={airline.id}>{airline.iata_code}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div ref={this.datepicker} className="input-group date mx-2">
                            <input type="text" className="form-control" defaultValue={moment(this.models('props.data.filter.date')).format("DD/MM/YYYY")}/>
                            <input type="hidden" name="date" value={this.state.date}/>
                            <div className="input-group-append"> 
                                <button className="btn-primary btn text-light" type="button"><i className="fa fa-calendar-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <a className="btn btn-primary" target='_blank' href={`/scans?format=pdf&date=${moment(this.models('props.data.filter.date')).format('YYYY-MM-DD')}&airline_id=${this.models('props.data.filter.airline_id')}`}>{trans('Télécharger')}</a>
                </div>
            </form>
            <div className='card mt-3'>
                <div className='card-body card-bureau'>
                    <h6>{trans('Sacs en excédent')} : {this.props.data.orphan_scans.length}</h6>
                    <div className='table-responsive'>
                        <table className="table table-bordered table-hover table-striped table-liste" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th>{trans('ULD')}</th>
                                    <th>{trans('Récipients')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.orphan_scans.map(orphan=><tr key={orphan.id}>
                                    <td>{orphan.uld.code}</td>
                                    <td>{orphan.receptacle_id}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* <div className='card mt-3'>
                <div className='card-body card-bureau'>
                    <h6>{trans('Sacs manquants')} : {this.props.data.nmissing_receptacles}</h6>
                    <div className='table-responsive'>
                        <table className="table table-bordered table-hover table-striped table-liste" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th>{trans('Cardits')}</th>
                                    <th>{trans('Récipients')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.missing_receptacles.map(cardit=><React.Fragment key={`cardit-${cardit.id}`}>
                                    {cardit.receptacles.map((receptacle, index)=>{
                                        return <tr key={`receptacle-${receptacle.id}`}>
                                            <td className='align-top'>{cardit.nsetup.document_number}</td>
                                            <td>{receptacle.nsetup.receptacle_id}</td>
                                        </tr>
                                    })}
                                </React.Fragment>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
            <div className='card mt-3'>
                <div className='card-body card-bureau'>
                    <h6>{trans('Sacs refusés')} : 0</h6>
                    <div className='table-responsive'>
                        <table className="table table-bordered table-hover table-striped table-liste" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th>{trans('Récipients')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.rejected_receptacles.map(orphan=><tr key={orphan.id}>
                                    <td>{orphan.receptacle_id}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='card mt-3'>
                <div className='card-body card-bureau'>
                    <h6>{trans('Sacs acceptés')} : {this.props.data.naccepted}</h6>
                    <div className='table-responsive'>
                        <table className="table table-bordered table-hover table-striped table-liste" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th>{trans('ULD')}</th>
                                    <th>{trans('Récipients')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.accepted_receptacles.map(uld=>
                                    uld.cardits.map((cardit, cardit_index)=>
                                        cardit.receptacles.map((receptacle, index)=>
                                            <tr key={`receptacle-${receptacle.id}`}>
                                                <td className='align-top'>{uld.code}</td>
                                                <td>{receptacle.nsetup.receptacle_id}</td>
                                            </tr>
                                        )
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Modelizer(List)