import React, {Component} from 'react';
import moment from 'moment';
import $ from 'jquery';
import Reception from './Reception';
import trans from '../../translations';
import Ry from 'ryvendor/Ry/Core/Ry';
import Modelizer from 'ryvendor/Ry/Core/Modelizer';
import {Popup, PopupHeader, PopupBody} from 'ryvendor/bs/bootstrap';

export class FullDetail extends Component
{
    constructor(props) {
        super(props)
        let data = this.props.data
        this.state = {
            data : this.props.data
        }
        this.offClick = this.offClick.bind(this)
    }

    offClick() {
        this.setState({
            select_airport : false
        })
        $('body').off('click', this.offClick)
    }
    getHeadStep() {
        return <div className="centerText">
            {trans("MLD RCF envoyé le :date", {
                date: moment.utc(this.models('props.data.handovered_at')).format('DD/MM/YYYY HH:mm')
            })}
            {(this.props.data.has_delivery == 1) ? <span> | {trans("MLD DLV envoyé le :date", {
                date: moment.utc(this.models('props.data.date_delivery')).format('DD/MM/YYYY HH:mm')
            })}</span> : null }
        </div>
    }

    render() {
        return <tr className={`detail`}>
        <td colSpan="16" className="no-padding">
            <div className="bandeau">
                <span className="title-bandeau">{trans('Liste des récipients')} </span>
                {this.getHeadStep()}
            </div>
            <div className="tableBottom">
                <Reception data={this.props.data} store={this.props.store} readOnly={this.props.readOnly}/>
            </div>
        </td>
    </tr>
    }
}

Modelizer(FullDetail)

class Item extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            data : null,
            open : false
        }
        this.detail = this.detail.bind(this)
    }

    detail(e) {
        e.preventDefault()
        if(this.state.data) {
            this.setState({
                open : !this.state.open
            })
            return false
        }
        $.ajax({
            url : '/cardit_import',
            data : {
                id : this.props.data.id,
                json : true
            },
            success : response=>{
                this.setState({
                    open : true,
                    data : response.data.data
                })
            }
        })
        return false
    }

    render() {
        let weight = 0
        this.models('props.data.receptacles', []).map(receptacle=>{
            weight += parseFloat(receptacle.weight)
        })
        return <React.Fragment>
            <Ry/>
            <tr>
                <td className="green">{moment.utc(this.props.data.handovered_at).format('DD/MM/YYYY HH:mm')}</td>
                <td className="green">
                    <div className="d-flex align-items-center px-3">
                        <span className={`d-inline-block px-2 list-document-number`}>{this.models('props.data.uld.code')}</span>
                        <a href="#" onClick={this.detail} className="btnAccord"><i className={`fa ${this.state.open?'fa-sort-up':'fa-sort-down'}`}></i></a>
                    </div>
                </td>
                <td>{this.models('props.data.origin_airport', '')}</td>
                <td>{moment.utc(this.models('props.data.arrival_datetime_lt')).format('DD/MM/YYYY HH:mm')}</td>
                {(this.props.data.attributed_carrier != null) ? <td>{this.models('props.data.attributed_carrier.iata_code')} <a href="#" onClick={e=>{
                    e.preventDefault()
                    $(`#conveyence-${this.props.data.id}`).modal('show')
                }}><i className="fa fa-info-circle text-turquoise"></i></a>
                    <Popup id={`conveyence-${this.props.data.id}`} className="airport-modal">
                        <PopupHeader className="pl-3 pb-2" closeButton={<span aria-hidden="true"  className="pb-1 pl-2 pr-2 rounded text-white" style={{background:'#170000'}}>&times;</span>}>
                            <h5><img src="/medias/images/ico-airport.png" className="position-absolute"/> <span className="pl-5 text-body">{trans('Carrier')}</span></h5>
                        </PopupHeader>
                        <hr className="border m-0 m-auto" style={{width:'calc(100% - 10px)', height:3}}/>
                        <PopupBody>
                            <div dangerouslySetInnerHTML={{__html:this.models('props.data.attributed_carrier.name')}}></div>
                        </PopupBody>
                    </Popup>
                </td> : <td>XX</td> }
                <td>{this.models('props.data.registered_conveyence_reference')}</td>
                <td>{this.models('props.data.receptacles', []).length}</td>
                <td>{weight.toFixed(2)}</td>
                <td><span className="btn-turquoise px-3 py-1 text-white w-auto" data-toggle="tooltip" data-placement="top" title={moment.utc(this.models('props.data.handovered_at')).format('DD/MM/YYYY HH:mm')}>RCF</span></td>
                <td>{(this.props.data.has_delivery == 0) ? <span className="btn-orange px-3 py-1 text-white w-auto">DLV</span> : <span className="btn-turquoise px-3 py-1 text-white w-auto" data-toggle="tooltip" data-placement="top" title={moment.utc(this.models('props.data.date_delivery')).format('DD/MM/YYYY HH:mm')}>DLV</span>}</td>
            </tr>
            {(this.state.data && this.state.open)?<FullDetail data={this.state.data} store={this.props.store} readOnly={this.props.readOnly}/>:null}
        </React.Fragment>
    }
}

export default Modelizer(Item);