import React from 'react';
import trans from '../../translations';
import Cardit from './Item';
import NavigableModel from 'ryvendor/Ry/Core/NavigableModel';
import $ from 'jquery';
import moment from 'moment';
import {Popup, PopupHeader, PopupBody} from 'ryvendor/bs/bootstrap';
import numeral from 'numeral';
import Modelizer from 'ryvendor/Ry/Core/Modelizer';
import swal from 'sweetalert2';
import './List.scss';
import axios from 'axios';

Array.prototype.groupBy = function(fn){
    let ar = []
    let subar = []
    this.map(it=>{
        let i = fn(it)
        if(ar.indexOf(i)<0)
            ar.push(fn(it))
    })
    ar.map(a=>subar.push(this.filter(it=>fn(it)==a)))
    return subar
}

class List extends NavigableModel
{
    constructor(props) {
        super(props)
        this.endpoint = '/cardits/imports'
        this.model = 'cardit'
        this.nopaginate = true
        this.readOnly = false
        this.state.date = moment().format('YYYY-MM-DD')
        this.state.datefilter = this.models('props.data.filter.handover_at_year') ? 'year' : 'date'

        this.state.data = this.props.data.data.data
        this.state.total = this.props.data.data.total
        let total_weight = 0
        let nreceptacles = 0
        this.state.data.map(item=>{
            item.receptacles.map(receptacle=> {
                total_weight += parseFloat(receptacle.weight)
                nreceptacles++
            })
        })
        this.state.total_weight = total_weight
        this.state.nreceptacles = nreceptacles
        this.state.last_page = this.props.data.data.last_page
        this.state.page = this.props.data.data.current_page ? this.props.data.data.current_page : 1
        this.state.months = ["Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"].map(it=>trans(it))
        this.state.monthvalue = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        this.state.exportdata = 0;

        this.state.errors = []
        this.state.filter = {
            handover_at_from : this.models('props.data.filter.handover_at_from', moment()),
            handover_at_to : this.models('props.data.filter.handover_at_to', moment()),
            handover_at_year : this.models('props.data.filter.handover_at_year', moment().year()),
            handover_at_month : this.models('props.data.filter.handover_at_month', this.state.monthvalue[moment().month()]),
            attributed_airline_id : this.models('props.data.filter.attributed_airline_id', ''), // Carrier
            uld_code : this.models('props.data.filter.uld_code', ''),
            origin_airport : this.models('props.data.filter.origin_airport', ''),
            receptacle_id : this.models('props.data.filter.receptacle_id', ''),
            origin_operator: this.models('props.data.filter.origin_operator', ''),
            dispatch_number: this.models('props.data.filter.dispatch_number', ''),
            conveyence_reference: this.models('props.data.filter.conveyence_reference', '')
        }
        this.xhrdata = {...this.state.filter}
        this.xhrdata.handover_at_from = moment(this.xhrdata.handover_at_from).format('YYYY-MM-DD')
        this.xhrdata.handover_at_to = moment(this.xhrdata.handover_at_to).format('YYYY-MM-DD')
        this.table = this.table.bind(this)
        this.beforelist = this.beforelist.bind(this)
        this.onFilter = this.onFilter.bind(this)
        let criterias = this.models('props.data.filter', {})
        this.data = {
            json : true,
            s : Array.isArray(criterias) ? {
                handover_at_to : moment().format('YYYY-MM-DD')
            } : criterias 
        }
        this.afterTd = this.afterTd.bind(this)
        this.afterTh = this.afterTh.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.handleCheckAll = this.handleCheckAll.bind(this)
        this.uldSearch = this.uldSearch.bind(this)
        this.originSearch = this.originSearch.bind(this)
        this.receptacleSearch = this.receptacleSearch.bind(this)
        this.disptachSearch = this.disptachSearch.bind(this)
        this.flightSearch = this.flightSearch.bind(this)
        this.handleDateChecked = this.handleDateChecked.bind(this)
        this.handleYearChecked = this.handleYearChecked.bind(this)
        this.handleYearChange = this.handleYearChange.bind(this)
        this.handleMonthChange = this.handleMonthChange.bind(this)
        // this.reception = this.reception.bind(this)
        this.handleHandoverAtChange = this.handleHandoverAtChange.bind(this)
        this.exportData = this.exportData.bind(this)
    }

    async exportData()
    {
        this.setState(state=>{
            state.exportdata = 1
            return state
        })
        let filename = "IMPORT "+moment().format("YYYYMMDDHHmmss")+".xlsx";
        await axios.post('/exporexcelimport', {data: "export import"}, { responseType: 'blob' })
        .then((response) => {
            console.log(response);
            let blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel'
            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            this.setState(state=>{
                state.exportdata = 0
                return state
            })
            link.click()
        })
        .catch((error) => console.log(error));
    }

    onProgress(state) {
        let total_weight=0;
        let nreceptacles=0;
        state.data.map(item=>{
            item.receptacles.map(receptacle=> {
                total_weight += parseFloat(receptacle.weight)
                nreceptacles++
            })
        })
        state.total_weight = total_weight
        state.nreceptacles = nreceptacles
    }

    flightSearch()
    {
        const field = 'flight'
        const value = this.refs.flight.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.flight = value
            state.data = []
            state.total = 0
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.handover_at_from
        delete this.data.s.handover_at_to
        delete this.data.s.handover_at_year
        delete this.data.s.handover_at_month
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState({
                    data : response.data.data,
                    last_page : response.data.last_page,
                    page : response.data.current_page,
                    total : response.data.total
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }
    
    originSearch() {
        const field = 'origin_airport'
        const value = this.refs.origin_airport.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.origin_airport = value
            state.data = []
            state.total = 0
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.handover_at_from
        delete this.data.s.handover_at_to
        delete this.data.s.handover_at_year
        delete this.data.s.handover_at_month
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState({
                    data : response.data.data,
                    last_page : response.data.last_page,
                    page : response.data.current_page,
                    total : response.data.total
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    uldSearch() {
        const field = 'uld_code'
        const value = this.refs.uld_code.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.uld_code = value
            state.data = []
            state.total = 0
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.handover_at_from
        delete this.data.s.handover_at_to
        delete this.data.s.handover_at_year
        delete this.data.s.handover_at_month
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState({
                    data : response.data.data,
                    last_page : response.data.last_page,
                    page : response.data.current_page,
                    total : response.data.total
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    disptachSearch() {
        const field = 'dispatch_number'
        const value = this.refs.dispatch_number.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.dispatch_number = value
            state.data = []
            state.total = 0
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.handover_at_from
        delete this.data.s.handover_at_to
        delete this.data.s.handover_at_year
        delete this.data.s.handover_at_month
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState({
                    data : response.data.data,
                    last_page : response.data.last_page,
                    page : response.data.current_page,
                    total : response.data.total
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    receptacleSearch() {
        const field = 'receptacle_id'
        const value = this.refs.receptacle_id.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.receptacle_id = value
            state.data = []
            state.total = 0
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.handover_at_from
        delete this.data.s.handover_at_to
        delete this.data.s.handover_at_year
        delete this.data.s.handover_at_month
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState({
                    data : response.data.data,
                    last_page : response.data.last_page,
                    page : response.data.current_page,
                    total : response.data.total
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    handleYearChecked(event) {
        const value = event.target.value
        if(event.target.checked) {
            if(this.pxhr)
                this.pxhr.abort()
            this.setState(state=>{
                state.data = []
                state.datefilter = value
                state.filter.uld_code = ''
                state.filter.origin_airport = ''
                state.filter.receptacle_id = ''
                state.filter.dispatch_number = ''
                state.filter.conveyence_reference = ''
                state.total = 0
                return state
            })
            this.urls = []
            this.data.s = {...this.state.filter}
            delete this.data.s.handover_at_from
            delete this.data.s.handover_at_to
            delete this.data.s.uld_code
            delete this.data.s.origin_airport
            delete this.data.s.receptacle_id
            delete this.data.s.dispatch_number
            delete this.data.s.conveyence_reference
            if(this.request) {
                this.request.abort()
            }
            this.request = $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data.data,
                        state.last_page = response.data.last_page
                        state.total = response.data.total
                        let total_weight = 0
                        let nreceptacles = 0
                        state.data.map(item=>{
                            item.receptacles.map(receptacle=> {
                                total_weight += parseFloat(receptacle.weight)
                                nreceptacles++
                            })
                        })
                        state.total_weight = total_weight
                        state.nreceptacles = nreceptacles
                        state.page = response.data.current_page
                        return state
                    })
                    window.setTimeout(this.progress, 100)
                }
            })
        }
    }

    handleHandoverAtChange(e) {
        const value = e.target.value
        this.setState(state=>{
            state.filter.handover_at_to = value
            return state
        })
    }

    handleDateChecked(event) {
        const value = event.target.value
        if(event.target.checked) {
            if(this.pxhr)
                this.pxhr.abort()
            this.setState(state=>{
                state.data = []
                state.datefilter = value
                state.total = 0
                state.filter.uld_code = ''
                state.filter.origin_airport = ''
                state.filter.receptacle_id = ''
                state.filter.dispatch_number = ''
                state.filter.conveyence_reference = ''
                return state
            })
            this.urls = []
            this.data.s = {...this.state.filter}
            if(this.data.s.handover_at_from)
                this.data.s.handover_at_from = moment(this.data.s.handover_at_from).format('YYYY-MM-DD')
            if(this.data.s.handover_at_to)
                this.data.s.handover_at_to = moment(this.data.s.handover_at_to).format('YYYY-MM-DD')
            delete this.data.s.uld_code
            delete this.data.s.origin_airport
            delete this.data.s.handover_at_year
            delete this.data.s.handover_at_month
            delete this.data.s.receptacle_id
            delete this.data.s.dispatch_number
            delete this.data.s.conveyence_reference
            if(this.request) {
                this.request.abort()
            }
            this.request = $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data.data,
                        state.last_page = response.data.last_page
                        state.page = response.data.current_page
                        let total_weight = 0
                        let nreceptacles = 0
                        state.data.map(item=>{
                            item.receptacles.map(receptacle=> {
                                total_weight += parseFloat(receptacle.weight)
                                nreceptacles++
                            })
                        })
                        state.total_weight = total_weight
                        state.nreceptacles = nreceptacles
                        state.total = response.data.total
                        return state
                    })
                    this.urls = []
                    window.setTimeout(this.progress, 100)
                }
            })
        }
    }

    
    handleMonthChange(event) {
        const value = event.target.value
        this.setState(state=>{
            state.filter.handover_at_month = value
            state.filter.uld_code = ''
                state.filter.origin_airport = ''
            state.filter.receptacle_id = ''
            state.filter.dispatch_number = ''
            state.filter.conveyence_reference = ''
            return state
        })
        if(this.state.datefilter=='year') {
            if(this.pxhr)
                this.pxhr.abort()
            this.data.s = {...this.state.filter}
            this.data.s.handover_at_month = value
            this.data.s.handover_at_year = this.state.handover_at_year
            this.setState(state=>{
                state.data = [],
                state.total = 0
                return state
            })
            this.urls = []
            delete this.data.s.handover_at_from
            delete this.data.s.handover_at_to
            delete this.data.s.uld_code
            delete this.data.s.origin_airport
            delete this.data.s.receptacle_id
            delete this.data.s.dispatch_number
            delete this.data.s.conveyence_reference
            if(this.request) {
                this.request.abort()
            }
            this.request = $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data.data,
                        state.last_page = response.data.last_page
                        state.page = response.data.current_page
                        let total_weight = 0
                        let nreceptacles = 0
                        state.data.map(item=>{
                            item.receptacles.map(receptacle=> {
                                total_weight += parseFloat(receptacle.weight)
                                nreceptacles++
                            })
                        })
                        state.total_weight = total_weight
                        state.nreceptacles = nreceptacles
                        state.total = response.data.total
                        return state
                    })
                    window.setTimeout(this.progress, 100)
                }
            })
        }
    }

    handleYearChange(event) {
        const value = event.target.value
        this.setState(state=>{
            state.filter.handover_at_year = value
            state.filter.uld_code = ''
                state.filter.origin_airport = ''
            state.filter.receptacle_id = ''
            state.filter.dispatch_number = ''
            state.filter.conveyence_reference = ''
            return state
        })
        if(this.state.datefilter=='year') {
            if(this.pxhr)
                this.pxhr.abort()
            this.data.s = {...this.state.filter}
            this.data.s.handover_at_year = value
            this.data.s.handover_at_month = this.state.handover_at_month
            this.setState(state=>{
                state.data = [],
                state.total = 0
                return state
            })
            this.urls = []
            delete this.data.s.handover_at_from
            delete this.data.s.handover_at_to
            delete this.data.s.uld_code
            delete this.data.s.origin_airport
            delete this.data.s.receptacle_id
            delete this.data.s.dispatch_number
            delete this.data.s.conveyence_reference
            if(this.request) {
                this.request.abort()
            }
            this.request = $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data.data,
                        state.last_page = response.data.last_page
                        state.page = response.data.current_page
                        let total_weight = 0
                        let nreceptacles = 0
                        state.data.map(item=>{
                            item.receptacles.map(receptacle=> {
                                total_weight += parseFloat(receptacle.weight)
                                nreceptacles++
                            })
                        })
                        state.total_weight = total_weight
                        state.nreceptacles = nreceptacles
                        state.total = response.data.total
                        return state
                    })
                    window.setTimeout(this.progress, 100)
                }
            })
        }
    }

    handleCheck(event, receptacle, cardit) {
        const value = event.target.checked
        this.setState(state=>{
            state.data.map(i_cardit=>{
                if(cardit.id==i_cardit.id) {
                    i_cardit.receptacles.map(i_receptacle=>{
                        if(i_receptacle.id==receptacle.id)
                            i_receptacle.selected = value
                    })
                }
            })
            return state
        })
    }


    handleCheckAll(event, cardit) {
        const value = event.target.checked
        this.setState(state=>{
            state.data.map(i_cardit=>{
                if(cardit.id==i_cardit.id) {
                    i_cardit.selected = value
                    i_cardit.receptacles.map(i_receptacle=>{
                        i_receptacle.selected = value
                    })
                }
            })
            return state
        })
    }

    onFilter(event, field) {
        const value = event.target.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.uld_code = ''
            state.filter.origin_airport = ''
            state.filter.receptacle_id = ''
            state.filter.dispatch_number = ''
            state.filter.conveyence_reference = ''
            state.filter[field] = value
            state.data = []
            state.total = 0
            return state
        })
        this.urls = []
        this.data.s = {...this.state.filter}
        if(this.state.datefilter=='year')
            delete this.data.s.handover_at_from
        if(this.state.datefilter=='date' && this.data.s.handover_at_from)
            this.data.s.handover_at_from = moment(this.data.s.handover_at_from).format('YYYY-MM-DD')
        if(this.state.datefilter=='date' && this.data.s.handover_at_to)
            this.data.s.handover_at_to = moment(this.data.s.handover_at_to).format('YYYY-MM-DD')
        delete this.data.s.uld_code
        delete this.data.s.origin_airport
        delete this.data.s.receptacle_id
        delete this.data.s.dispatch_number
        delete this.data.s.conveyence_reference
        this.data.s[field] = value
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState(state=>{
                    state.data = response.data.data
                    state.last_page = response.data.last_page
                    state.page = response.data.current_page
                    let total_weight = 0
                    let nreceptacles = 0
                    state.data.map(item=>{
                        item.receptacles.map(receptacle=> {
                            total_weight += parseFloat(receptacle.weight)
                            nreceptacles++
                        })
                    })
                    state.total_weight = total_weight
                    state.nreceptacles = nreceptacles
                    state.total = response.data.total
                    return state
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    componentDidMount() {
        super.componentDidMount()
        const opts = {
            language : 'fr',
            autoclose : true
        }
        const dp = $(this.refs.datepicker).datepicker(opts)
        const dp_to = $(this.refs.datepicker_to).datepicker(opts)
        dp.on("changeDate", ()=>{
            if(this.state.datefilter!='date')
                return

            const date = moment(dp.datepicker('getDate')).format('YYYY-MM-DD')
            if(this.pxhr)
                this.pxhr.abort()
            this.setState(state=>{
                state.filter.handover_at_from = date
                state.filter.receptacle_id = ''
                state.filter.uld_code = ''
                state.filter.origin_airport = ''
                state.date = date
                state.data = []
                state.total = 0
                return state
            })
            this.urls = []
            this.data.s.handover_at_from = date
            delete this.data.s.handover_at_year
            delete this.data.s.receptacle_id
            delete this.data.s.uld_code
            delete this.data.s.origin_airport
            $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data.data,
                        state.last_page = response.data.last_page
                        state.page = response.data.current_page
                        let total_weight = 0
                        let nreceptacles = 0
                        state.data.map(item=>{
                            item.receptacles.map(receptacle=> {
                                total_weight += parseFloat(receptacle.weight)
                                nreceptacles++
                            })
                        })
                        state.total_weight = total_weight
                        state.nreceptacles = nreceptacles
                        state.total = response.data.total
                        return state
                    })
                    window.setTimeout(this.progress, 1)
                }
            })
        });
        dp_to.on("changeDate", ()=>{
            if(this.state.datefilter!='date')
                return

            const date = moment(dp_to.datepicker('getDate')).format('YYYY-MM-DD')
            if(this.pxhr)
                this.pxhr.abort()
            this.setState(state=>{
                state.filter.handover_at_to = date
                state.filter.receptacle_id = ''
                state.filter.uld_code = ''
                state.filter.origin_airport = ''
                state.date = date
                state.data = []
                state.total = 0
                return state
            })
            this.urls = []
            this.data.s.handover_at_to = date
            delete this.data.s.handover_at_year
            delete this.data.s.receptacle_id
            delete this.data.s.uld_code
            delete this.data.s.origin_airport
            $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data.data,
                        state.last_page = response.data.last_page
                        state.page = response.data.current_page
                        let total_weight = 0
                        let nreceptacles = 0
                        state.data.map(item=>{
                            item.receptacles.map(receptacle=> {
                                total_weight += parseFloat(receptacle.weight)
                                nreceptacles++
                            })
                        })
                        state.total_weight = total_weight
                        state.nreceptacles = nreceptacles
                        state.total = response.data.total
                        return state
                    })
                    window.setTimeout(this.progress, 1)
                }
            })
        });
        $(this.refs.frm_receptacles).ajaxForm()
        this.props.store.subscribe(()=>{
            const storeState = this.props.store.getState()
            if(storeState.type=='cardit' && storeState.row) {
                this.setState(state=>{
                    state.data = state.data.filter(it=>(it.nsetup.uld_code!=storeState.row.nsetup.uld_code || (it.nsetup.uld_code==storeState.row.nsetup.uld_code && it.id==storeState.row.id)))
                    let total_weight=0;
                    let nreceptacles = 0
                    state.data.map(item=>{
                        item.receptacles.map(receptacle=> {
                            total_weight += parseFloat(receptacle.weight)
                            nreceptacles++
                        })
                    })
                    state.total_weight = total_weight
                    state.nreceptacles = nreceptacles
                    return state
                })
            }
        })
    }


    afterTd(cardit) {

    }

    afterTh() {

    }
    beforelist() {
        return <div className="d-flex justify-content-center">
            {/* <label className="btn btn-orange mr-2 my-0" data-name="import" data-dropzone-action="/upload_import" data-any-file="true">{trans('Importer')}</label> */}
            <button className="btn btn-primary" onClick={this.exportData} disabled={(this.state.data.length <= 0) ? true : false} type='button'>{(this.state.exportdata == 0) ? trans('Exporter') : <i class="fas fa-spinner fa-spin"></i>}</button>
        </div>
    }

    table() {
        return <React.Fragment>
            <table className="table table-bordered table-hover table-striped table-liste" cellSpacing="0" cellPadding="0" id="tableImport">
                <thead>
                    <tr>
                        <th>{trans('Handover DateTime')}</th>
                        <th>{trans('ULD')}</th>
                        <th>{trans("Origine airport")}</th>
                        <th>{trans('Actual arrival')}</th>
                        <th>{trans('Carrier')}</th>
                        <th>{trans('Flight Nbr')}</th>
                        <th>{trans('Qté')}</th>
                        <th>{trans('Weight')}</th>
                        <th>{trans('RCF')}</th>
                        <th>{trans('DLV')}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.data.map(item=><Cardit readOnly={this.readOnly} data={item} store={this.props.store} key={`cardit-${item.id}`}/>)}
                </tbody>
                <tfoot className={(this.progressive && this.state.page<this.state.last_page)?'':'d-none'}>
                    <tr>
                        <td ref="overscroller" colSpan="14" className={`position-relative py-3`}><i className="spinner"></i></td>
                    </tr>
                </tfoot>
            </table>
        </React.Fragment>
    }
    
    render()
    {
        let years = []
        for(var i=moment().year();i>=2019;i--){
             years.push(i)                                   
        }
        let pagination = <React.Fragment>
            <a href="#" onClick={this.toFirst} className={this.state.page===1?'disabled':''}><i className="fa fa-angle-double-left"></i></a>
            <a href="#" onClick={this.toPrevious} className={this.state.page===1?'disabled':''}><i className="fa fa-angle-left"></i></a>
            <a href="#" onClick={this.toNext} className={this.state.page===this.state.last_page?'disabled':''}><i className="fa fa-angle-right"></i></a>
            <a href="#" onClick={this.toEnd} className={this.state.page===this.state.last_page?'disabled':''}><i className="fa fa-angle-double-right"></i></a>
        </React.Fragment>
        let nreceptacles = 0
        let wreceptacles = 0
        this.state.data.map(item=>{
            nreceptacles += parseInt(item.receptacles.length)
            item.receptacles.map(receptacle=>{
                wreceptacles += parseFloat(receptacle.weight)
            })
        })

        return <div className="cardit-container vol-liste col-md-12">
            <div className="row clearfix align-items-stretch position-relative vol-container">
                <div className="col-12">
                    <div className="topContainer mb-2 d-flex justify-content-between align-items-center">
                        <div className="col-md-12">
                            <div className="align-items-baseline row">
                                <div className={`col-md-3`}>
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <label className="control-label mr-2">{trans('Carrier')}</label>
                                        <select className="form-control" value={this.state.filter.attributed_airline_id} onChange={e=>this.onFilter(e, 'attributed_airline_id')}>
                                            <option value="">{trans('Tous')}</option>
                                            {this.props.data.airlines.map(airline=><option key={`select-attributed-carrier-${airline.id}`} value={airline.id}>{airline.iata_code}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className={`col-md-3`}>
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <label className="control-label mr-2" style={{width: "160px"}}>{trans('Origin operator')}</label>
                                        <select className="form-control" value={this.state.filter.from_airport_id} onChange={e=>this.onFilter(e, 'from_airport_id')}>
                                            <option value="">{trans('Tous')}</option>
                                            {this.props.data.select_origins.map(airport=><option key={`select-provenance-${airport.id}`} value={airport.id}>{airport.iata}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 border rounded p-2">
                                    <div className="d-flex">
                                        <label className="fancy-radio m-auto custom-color-green">
                                            <input name={`date-filter`} type="radio" value="date" onChange={this.handleDateChecked} checked={this.state.datefilter=='date'}/>
                                            <span><i className="mr-0"></i></span>
                                        </label>
                                        <div ref="datepicker" className="input-group date mx-2">
                                            <input type="text" className="form-control" defaultValue={moment(this.state.filter.handover_at_from).format("DD/MM/YYYY")}/>
                                            <div className="input-group-append"> 
                                                <button className="btn-primary btn text-light" type="button"><i className="fa fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div className="form-group m-auto">
                                            <label className="control-label mx-2 mb-0">{trans('au')}</label>
                                        </div>
                                        <div ref="datepicker_to" className="input-group date mx-2">
                                            <input type="text" className="form-control" value={moment(this.state.filter.handover_at_to).format("DD/MM/YYYY")} onChange={this.handleHandoverAtChange}/>
                                            <div className="input-group-append">
                                                <button className="btn-primary btn text-light" type="button"><i className="fa fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div className="form-group m-auto">
                                            <label className="control-label ml-5 mr-2 mb-0">{trans('ou')}</label>
                                        </div>
                                        <label className="fancy-radio m-auto custom-color-green mx-2">
                                            <input name={`date-filter`} type="radio" value="year" onChange={this.handleYearChecked} checked={this.state.datefilter=='year'}/>
                                            <span><i className="mr-0"></i></span>
                                        </label>
                                        <select onChange={this.handleMonthChange} className="form-control mx-2">
                                            {this.state.months.map((month, index)=><option key={index} value={this.state.monthvalue[index]}>{month}</option>)}
                                        </select>
                                        <select onChange={this.handleYearChange} className="form-control mx-2">
                                            {years.map(year=><option key={year} value={year}>{year}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="align-items-baseline row">
                                <div className="col-md-3">
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <div className="input-group">
                                            <input ref="flight" type="search" placeholder={trans("Flight")} value={this.state.filter.flight} className="form-control" onChange={e=>{
                                                const value = e.target.value
                                                this.setState(state=>{
                                                    state.filter.flight = value
                                                    return state
                                                })
                                            }}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={this.flightSearch}>{trans('OK')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <div className="input-group">
                                            <input ref="receptacle_id" placeholder={trans("Receptacle ID")} type="search" value={this.state.filter.receptacle_id} className="form-control" onChange={e=>{
                                                const value = e.target.value
                                                this.setState(state=>{
                                                    return state.filter.receptacle_id = value
                                                })
                                            }}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={this.receptacleSearch}>{trans('OK')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <div className="input-group">
                                            <input ref="origin_airport" type="search" placeholder={trans("Origin airport")} value={this.state.filter.origin_airport} className="form-control" onChange={e=>{
                                                const value = e.target.value
                                                this.setState(state=>{
                                                    state.filter.origin_airport = value
                                                    return state
                                                })
                                            }}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={this.originSearch}>{trans('OK')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <div className="input-group">
                                            <input ref="uld_code" type="search" placeholder={trans("Nº d'ULD")} value={this.state.filter.uld_code} className="form-control" onChange={e=>{
                                                const value = e.target.value
                                                this.setState(state=>{
                                                    state.filter.uld_code = value
                                                    return state
                                                })
                                            }}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={this.uldSearch}>{trans('OK')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <div className="input-group">
                                            <input ref="dispatch_number" type="search" placeholder={trans("Disptach Number")} value={this.state.filter.dispatch_number} className="form-control" onChange={e=>{
                                                const value = e.target.value
                                                this.setState(state=>{
                                                    state.filter.dispatch_number = value
                                                    return state
                                                })
                                            }}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={this.disptachSearch}>{trans('OK')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="navPager d-flex align-items-center justify-content-end">
                            {this.nopaginate?null:pagination}
                        </div>
                    </div>
                    <div className="card overflowhidden">
                        <div className="body">
                            <div className="row m-0 justify-content-between">
                                <ul className='list list-inline mb-0'>
                                    <li className='list-inline-item mr-3'><h6 style={{fontWeight: 'bolder'}}>{trans('RECEPTIONS IMPORT')}</h6></li>
                                    <li className='list-inline-item mr-3'>{trans("Nombre d'ULD")} : <strong>{this.state.data.length}</strong></li>
                                    <li className='list-inline-item mr-3'>{trans("Nombre de récipients")} : <strong>{nreceptacles}</strong></li>
                                    <li className='list-inline-item mr-3'>{trans("Poids total")} : <strong>{numeral(wreceptacles).format('0,0.00')} {trans('Kg')}</strong></li>
                                </ul>
                                {this.beforelist()}
                            </div>
                            <div className="card-bureau no-border p-0">
                                <div className="table-responsive">
                                    {this.table()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="navPager d-flex align-items-center justify-content-end">
                            {this.nopaginate?null:pagination}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
Modelizer(List)

export default List;