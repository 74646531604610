import React, {Component} from 'react';
import Modelizer from 'ryvendor/Ry/Core/Modelizer';
import moment from "moment/moment";
import $ from "jquery";
import numeral from "numeral";
import './dashboard.scss';
import turndown from '../../medias/images/turn-right.png';
import swal from 'sweetalert2';
import { Doughnut } from "react-chartjs-2";
import axios from 'axios';
import trans, {DATES} from '../../gha/translations';

class Dashboard extends Component
{
    constructor(props) {
        super(props)
        this.state = {}
        this.endpoint = '/'
        this.state.date = moment().format('YYYY-MM-DD')
        this.state.datefilter = this.models('props.data.filter.departure_datefrom') ? 'date' : 'month'
        this.state.filter = {
            departure_datefrom : this.models('props.data.filter.departure_datefrom', moment()),
            departure_dateto : this.models('props.data.filter.departure_dateto', moment()),
            airline_id : '',
            month : this.models('props.data.filter.month', ''),
            flight_number : this.models('props.data.filter.flight_number', ''),
            uld_number : this.models('props.data.filter.uld_number', ''),
            cardit_number : this.models('props.data.filter.cardit_number', ''),
            receptacle_id : this.models('props.data.filter.receptacle_id', ''),
            departure_location : this.models('props.data.filter.departure_location', ''),
            arrival_location : this.models('props.data.filter.arrival_location', ''),
        }
        this.xhrdata = {...this.state.filter}
        this.xhrdata.departure_datefrom = moment(this.xhrdata.departure_datefrom).format('YYYY-MM-DD')
        this.xhrdata.departure_dateto = moment(this.xhrdata.departure_dateto).format('YYYY-MM-DD')
        let criterias = this.models('props.data.filter', {})
        this.data = {
            json : true,
            s : Array.isArray(criterias) ? {
                month : moment().format('MM')
            } : criterias 
        }
        this.state.backcolors = ['#11446a', '#e8cd62','#4e73df', '#1cc88a', '#36b9cc', '#944EDF', '#F66363', '#34495E', '#C1CF1F', '#E59866', '#76D7C4', '#EB984E', '#E74C3C', '#A6ACAF']
        this.state.hovercolors = ['#0C3656', '#D8C05E', '#2e59d9', '#17a673', '#2c9faf', '#742ED9', '#D92E2E', '#2C3E50', '#A3AF12', '#E5935B', '#48C9B0', '#E67E22', '#CB4335', '#909497']
        
        this.state.data = this.props.data.data

        this.state.currentmonth = this.props.data.currentmonth
        this.state.destinations = this.props.data.destinations
        this.state.piecesbydestinations = this.props.data.piecesbydestinations
        this.state.weightbydestinations = this.props.data.weightbydestinations
        this.state.selectedmonth = this.props.data.selectedmonth
        this.state.currentmonth = this.props.data.currentmonth
        this.state.nbsacs = this.props.data.nbsacs
        this.state.total_weight = this.props.data.total_weight
        this.state.destinations = this.props.data.destinations
        this.state.piecesbydestinations = this.props.data.piecesbydestinations
        this.state.weightbydestinations = this.props.data.weightbydestinations
        this.state.selectedyear = this.props.data.selectedyear

        this.state.exportSynthese = 0;
        this.state.exportBrute = 0;

        this.state.datachart = {
            labels: this.state.destinations, 
            datasets: [
                {
                    data: this.state.piecesbydestinations, 
                    backgroundColor: this.state.backcolors.slice(0, ((this.state.destinations) ? this.state.destinations.length : 0)),
                    weights: this.state.weightbydestinations
                }
            ]
        }

        this.state.optionchart = {
            responsive: false,
            maintainAspectRatio: false,
            cutoutPercentage: 80,
            legend: {
                display: true,
                position: 'bottom',                 
                labels: {
                        fontSize: 14,
                        fontStyle: 'bold'
                    }
            },
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label:  function(tooltipItem, data) {
                        return 'Pcs : '+data['datasets'][0]['data'][tooltipItem['index']];
                    },
                    afterLabel: function(tooltipItem, data) {
                        return "Poids : "+numeral(data['datasets'][0]['weights'][tooltipItem['index']]).format('0,0.0')+" Kg";
                    }
                },
                backgroundColor: "rgba(255,255,255,1)",
                bodyFontColor: "#858796",
                titleMarginBottom: 10,
                titleFontColor: '#6e707e',
                titleFontSize: 14,
                borderColor: '#dddfeb',
                borderWidth: 1,
                xPadding: 15,
                yPadding: 15,
                displayColors: false,
                intersect: true,
                mode: 'index',
                caretPadding: 10,
                titleAlign: 'center'
            },
            title: {
                display: false,
            }
        }


        
        this.handleDateChecked = this.handleDateChecked.bind(this)
        this.handleMonthChecked = this.handleMonthChecked.bind(this)
        this.airlineSearch = this.airlineSearch.bind(this)
        this.originSearch = this.originSearch.bind(this)
        this.destinationSearch = this.destinationSearch.bind(this)
        this.handleDateToChanged = this.handleDateToChanged.bind(this)
        this.flightSearch = this.flightSearch.bind(this)
        this.uldSearch = this.uldSearch.bind(this)
        this.carditSearch = this.carditSearch.bind(this)
        this.receptacleSearch = this.receptacleSearch.bind(this)
        this.handleMonthChange = this.handleMonthChange.bind(this)
        this.handleOpenClose = this.handleOpenClose.bind(this)
        this.exportData = this.exportData.bind(this)
        this.exportDataSynthese = this.exportDataSynthese.bind(this)
    }

    componentDidMount() {
        console.log(this.props.data);
        const opts = {
            language : 'fr',
            autoclose : true
        }
        const dp = $(this.refs.datepicker).datepicker(opts)
        const dp_to = $(this.refs.datepicker_to).datepicker(opts)
        dp.on("changeDate", ()=>{
            if(this.state.datefilter!='date')
                return

            const date = moment(dp.datepicker('getDate')).format('YYYY-MM-DD')
            if(this.pxhr)
                this.pxhr.abort()
            this.setState(state=>{
                state.filter.departure_datefrom = date
                state.filter.flight_number = ''
                state.filter.uld_number = ''
                state.filter.cardit_number = ''
                state.filter.receptacle_id = ''
                state.date = date
                return state
            })
            this.urls = []
            this.data.s.departure_datefrom = date
            delete this.data.s.month
            delete this.data.s.flight_number
            delete this.data.s.uld_number
            delete this.data.s.cardit_number
            delete this.data.s.receptacle_id
            $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    console.log(response)
                    this.setState(state=>{
                        state.data = response.data
                        state.selectedmonth = response.selectedmonth
                        state.currentmonth = response.currentmonth
                        state.nbsacs = response.nbsacs
                        state.total_weight = response.total_weight
                        state.destinations = response.destinations
                        state.piecesbydestinations = response.piecesbydestinations
                        state.weightbydestinations = response.weightbydestinations
                        state.datachart = {
                            labels: response.destinations, 
                            datasets: [
                                {
                                    data: this.state.piecesbydestinations, 
                                    backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                    weights: response.weightbydestinations
                                }
                            ]
                        }
                        return state
                    })
                    window.setTimeout(this.progress, 1)
                }
            })
        });
        dp_to.on("changeDate", ()=>{
            if(this.state.datefilter!='date')
                return

            const date = moment(dp_to.datepicker('getDate')).format('YYYY-MM-DD')
            if(this.pxhr)
                this.pxhr.abort()
            this.setState(state=>{
                state.filter.departure_dateto = date
                state.filter.flight_number = ''
                state.filter.uld_number = ''
                state.filter.cardit_number = ''
                state.filter.receptacle_id = ''
                state.date = date
                return state
            })
            this.urls = []
            this.data.s.departure_dateto = date
            delete this.data.s.month
            delete this.data.s.flight_number
            delete this.data.s.uld_number
            delete this.data.s.cardit_number
            delete this.data.s.receptacle_id
            $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data
                        state.selectedmonth = response.selectedmonth
                        state.currentmonth = response.currentmonth
                        state.nbsacs = response.nbsacs
                        state.total_weight = response.total_weight
                        state.destinations = response.destinations
                        state.piecesbydestinations = response.piecesbydestinations
                        state.weightbydestinations = response.weightbydestinations
                        state.datachart = {
                            labels: response.destinations, 
                            datasets: [
                                {
                                    data: this.state.piecesbydestinations, 
                                    backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                    weights: response.weightbydestinations
                                }
                            ]
                        }
                        return state
                    })
                    window.setTimeout(this.progress, 1)
                }
            })
        });
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    handleDateToChanged(e) {
        const value = e.target.value
        this.setState(state=>{
            state.filter.departure_dateto = value
            return state
        })
    }

    handleMonthChange(event) {
        const value = event.target.value
        this.setState(state=>{
            state.filter.month = value
            state.filter.flight_number = ''
            state.filter.uld_number = ''
            state.filter.cardit_number = ''
            state.filter.receptacle_id = ''
            return state
        })
        if(this.state.datefilter=='month') {
            if(this.pxhr)
                this.pxhr.abort()
            this.data.s = {...this.state.filter}
            this.data.s.month = value
            this.setState(state=>{
                return state
            })
            this.urls = []
            delete this.data.s.departure_datefrom
            delete this.data.s.departure_dateto
            delete this.data.s.flight_number
            delete this.data.s.uld_number
            delete this.data.s.cardit_number
            delete this.data.s.receptacle_id
            if(this.request) {
                this.request.abort()
            }
            this.request = $.ajax({
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data
                        state.selectedmonth = response.selectedmonth
                        state.currentmonth = response.currentmonth
                        state.nbsacs = response.nbsacs
                        state.total_weight = response.total_weight
                        state.destinations = response.destinations
                        state.piecesbydestinations = response.piecesbydestinations
                        state.weightbydestinations = response.weightbydestinations
                        state.datachart = {
                            labels: response.destinations, 
                            datasets: [
                                {
                                    data: this.state.piecesbydestinations, 
                                    backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                    weights: response.weightbydestinations
                                }
                            ]
                        }
                        return state
                    })
                    window.setTimeout(this.progress, 100)
                }
            })
        }
    }
    originSearch()
    {
        const value = this.refs.origin.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.flight_number = ''
            state.filter.uld_number = ''
            state.filter.cardit_number = ''
            state.filter.receptacle_id = ''
            state.filter.departure_location = value
            return state
        })
        this.urls = []
        this.data.s = {...this.state.filter}

        if(this.state.datefilter=='month')
        {
            delete this.data.s.departure_datefrom
            delete this.data.s.departure_dateto
        } else {
            if(this.state.datefilter=='date' && this.data.s.departure_datefrom)
                this.data.s.departure_datefrom = moment(this.data.s.departure_datefrom).format('YYYY-MM-DD')
            if(this.state.datefilter=='date' && this.data.s.departure_dateto)
                this.data.s.departure_dateto = moment(this.data.s.departure_dateto).format('YYYY-MM-DD')
        }
        
        delete this.data.s.flight_number
        delete this.data.s.uld_number
        delete this.data.s.cardit_number
        delete this.data.s.receptacle_id
        this.data.s.departure_location = value
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                console.log(response)
                this.setState(state=>{
                    state.data = response.data
                    state.selectedmonth = response.selectedmonth
                    state.currentmonth = response.currentmonth
                    state.nbsacs = response.nbsacs
                    state.total_weight = response.total_weight
                    state.destinations = response.destinations
                    state.piecesbydestinations = response.piecesbydestinations
                    state.weightbydestinations = response.weightbydestinations
                    state.datachart = {
                        labels: response.destinations, 
                        datasets: [
                            {
                                data: this.state.piecesbydestinations, 
                                backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                weights: response.weightbydestinations
                            }
                        ]
                    }
                    return state
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    destinationSearch()
    {
        const value = this.refs.destination.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.flight_number = ''
            state.filter.uld_number = ''
            state.filter.cardit_number = ''
            state.filter.receptacle_id = ''
            state.filter.arrival_location = value
            return state
        })
        this.urls = []
        this.data.s = {...this.state.filter}

        if(this.state.datefilter=='month')
        {
            delete this.data.s.departure_datefrom
            delete this.data.s.departure_dateto
        } else {
            if(this.state.datefilter=='date' && this.data.s.departure_datefrom)
                this.data.s.departure_datefrom = moment(this.data.s.departure_datefrom).format('YYYY-MM-DD')
            if(this.state.datefilter=='date' && this.data.s.departure_dateto)
                this.data.s.departure_dateto = moment(this.data.s.departure_dateto).format('YYYY-MM-DD')
        }

        delete this.data.s.flight_number
        delete this.data.s.uld_number
        delete this.data.s.cardit_number
        delete this.data.s.receptacle_id
        this.data.s.arrival_location = value
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                console.log(response)
                this.setState(state=>{
                    state.data = response.data
                    state.selectedmonth = response.selectedmonth
                    state.currentmonth = response.currentmonth
                    state.nbsacs = response.nbsacs
                    state.total_weight = response.total_weight
                    state.destinations = response.destinations
                    state.piecesbydestinations = response.piecesbydestinations
                    state.weightbydestinations = response.weightbydestinations
                    state.datachart = {
                        labels: response.destinations, 
                        datasets: [
                            {
                                data: this.state.piecesbydestinations, 
                                backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                weights: response.weightbydestinations
                            }
                        ]
                    }
                    return state
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    airlineSearch() {
        const field = 'airline_id'
        const value = this.refs.airline.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.flight_number = ''
            state.filter.uld_number = ''
            state.filter.cardit_number = ''
            state.filter.receptacle_id = ''
            state.filter.airline_id = value
            return state
        })
        this.urls = []
        this.data.s = {...this.state.filter}
        if(this.state.datefilter=='month')
        {
            delete this.data.s.departure_datefrom
            delete this.data.s.departure_dateto
        } else {
            if(this.state.datefilter=='date' && this.data.s.departure_datefrom)
                this.data.s.departure_datefrom = moment(this.data.s.departure_datefrom).format('YYYY-MM-DD')
            if(this.state.datefilter=='date' && this.data.s.departure_dateto)
                this.data.s.departure_dateto = moment(this.data.s.departure_dateto).format('YYYY-MM-DD')
        }
        delete this.data.s.flight_number
        delete this.data.s.uld_number
        delete this.data.s.cardit_number
        delete this.data.s.receptacle_id
        this.data.s.airline_id = value
        if(this.request) {
            this.request.abort()
        }
        console.log(this.state.datefilter)
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                console.log(response)
                this.setState(state=>{
                    state.data = response.data
                    state.selectedmonth = response.selectedmonth
                    state.currentmonth = response.currentmonth
                    state.nbsacs = response.nbsacs
                    state.total_weight = response.total_weight
                    state.destinations = response.destinations
                    state.piecesbydestinations = response.piecesbydestinations
                    state.weightbydestinations = response.weightbydestinations
                    state.datachart = {
                        labels: response.destinations, 
                        datasets: [
                            {
                                data: this.state.piecesbydestinations, 
                                backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                weights: response.weightbydestinations
                            }
                        ]
                    }
                    return state
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    flightSearch() {
        const field = 'flight_number'
        const value = this.refs.flight_number.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.flight_number = value
            state.filter.uld_number = ''
            state.filter.cardit_number = ''
            state.filter.receptacle_id = ''
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.departure_datefrom
        delete this.data.s.departure_dateto
        delete this.data.s.month
        delete this.data.s.departure_location
        delete this.data.s.arrival_location
        delete this.data.s.uld_number
        delete this.data.s.cardit_number
        delete this.data.s.receptacle_id
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState(state=>{
                    state.data = response.data
                    state.selectedmonth = response.selectedmonth
                    state.currentmonth = response.currentmonth
                    state.nbsacs = response.nbsacs
                    state.total_weight = response.total_weight
                    state.destinations = response.destinations
                    state.piecesbydestinations = response.piecesbydestinations
                    state.weightbydestinations = response.weightbydestinations
                    state.datachart = {
                        labels: response.destinations, 
                        datasets: [
                            {
                                data: this.state.piecesbydestinations, 
                                backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                weights: response.weightbydestinations
                            }
                        ]
                    }
                    return state
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    uldSearch() {
        const field = 'uld_number'
        const value = this.refs.uld_number.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.uld_number = value
            state.filter.flight_number = ''
            state.filter.cardit_number = ''
            state.filter.receptacle_id = ''
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.departure_datefrom
        delete this.data.s.departure_dateto
        delete this.data.s.month
        delete this.data.s.departure_location
        delete this.data.s.arrival_location
        delete this.data.s.flight_number
        delete this.data.s.cardit_number
        delete this.data.s.receptacle_id
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState(state=>{
                    state.data = response.data
                    state.selectedmonth = response.selectedmonth
                    state.currentmonth = response.currentmonth
                    state.nbsacs = response.nbsacs
                    state.total_weight = response.total_weight
                    state.destinations = response.destinations
                    state.piecesbydestinations = response.piecesbydestinations
                    state.weightbydestinations = response.weightbydestinations
                    state.datachart = {
                        labels: response.destinations, 
                        datasets: [
                            {
                                data: this.state.piecesbydestinations, 
                                backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                weights: response.weightbydestinations
                            }
                        ]
                    }
                    return state
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }


    carditSearch() {
        const field = 'cardit_number'
        const value = this.refs.cardit_number.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.cardit_number = value
            state.filter.flight_number = ''
            state.filter.uld_number = ''
            state.filter.receptacle_id = ''
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.departure_datefrom
        delete this.data.s.departure_dateto
        delete this.data.s.month
        delete this.data.s.departure_location
        delete this.data.s.arrival_location
        delete this.data.s.flight_number
        delete this.data.s.uld_number
        delete this.data.s.receptacle_id
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState(state=>{
                    state.data = response.data
                    state.selectedmonth = response.selectedmonth
                    state.currentmonth = response.currentmonth
                    state.nbsacs = response.nbsacs
                    state.total_weight = response.total_weight
                    state.destinations = response.destinations
                    state.piecesbydestinations = response.piecesbydestinations
                    state.weightbydestinations = response.weightbydestinations
                    state.datachart = {
                        labels: response.destinations, 
                        datasets: [
                            {
                                data: this.state.piecesbydestinations, 
                                backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                weights: response.weightbydestinations
                            }
                        ]
                    }
                    return state
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    receptacleSearch() {
        const field = 'receptacle_id'
        const value = this.refs.receptacle_id.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter.receptacle_id = value
            state.filter.flight_number = ''
            state.filter.uld_number = ''
            state.filter.cardit_number = ''
            return state
        })
        this.urls = []
        this.data.s[field] = value
        delete this.data.s.departure_datefrom
        delete this.data.s.departure_dateto
        delete this.data.s.month
        delete this.data.s.departure_location
        delete this.data.s.arrival_location
        delete this.data.s.flight_number
        delete this.data.s.uld_number
        delete this.data.s.cardit_number
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState(state=>{
                    state.data = response.data
                    state.selectedmonth = response.selectedmonth
                    state.currentmonth = response.currentmonth
                    state.nbsacs = response.nbsacs
                    state.total_weight = response.total_weight
                    state.destinations = response.destinations
                    state.piecesbydestinations = response.piecesbydestinations
                    state.weightbydestinations = response.weightbydestinations
                    state.datachart = {
                        labels: response.destinations, 
                        datasets: [
                            {
                                data: this.state.piecesbydestinations, 
                                backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                weights: response.weightbydestinations
                            }
                        ]
                    }
                    return state
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }

    handleDateChecked(event) {
        const value = event.target.value
        if(event.target.checked) {
            if(this.pxhr)
                this.pxhr.abort()
            this.setState(state=>{
                state.datefilter = value
                state.filter.flight_number = ''
                state.filter.uld_number = ''
                state.filter.cardit_number = ''
                state.filter.receptacle_id = ''
                return state
            })
            this.urls = []
            this.data.s = {...this.state.filter}
            if(this.data.s.departure_datefrom)
                this.data.s.departure_datefrom = moment(this.state.filter.departure_datefrom).format('YYYY-MM-DD')
            if(this.data.s.departure_dateto)
                this.data.s.departure_dateto = moment(this.state.filter.departure_dateto).format('YYYY-MM-DD')

            delete this.data.s.flight_number
            delete this.data.s.uld_number
            delete this.data.s.cardit_number
            delete this.data.s.receptacle_id
            delete this.data.s.month

            if(this.request) {
                this.request.abort()
            }
            this.request = $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data
                        state.selectedmonth = response.selectedmonth
                        state.currentmonth = response.currentmonth
                        state.nbsacs = response.nbsacs
                        state.total_weight = response.total_weight
                        state.destinations = response.destinations
                        state.piecesbydestinations = response.piecesbydestinations
                        state.weightbydestinations = response.weightbydestinations
                        state.datachart = {
                            labels: response.destinations, 
                            datasets: [
                                {
                                    data: this.state.piecesbydestinations, 
                                    backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                    weights: response.weightbydestinations
                                }
                            ]
                        }
                        return state
                    })
                    this.urls = []
                    window.setTimeout(this.progress, 100)
                }
            })
        }
    }

    handleMonthChecked(event) {
        const value = event.target.value
        this.setState(state=>{
            state.datefilter = value
            state.filter.flight_number = ''
            state.filter.uld_number = ''
            state.filter.cardit_number = ''
            state.filter.receptacle_id = ''
            return state
        })
        if(this.state.datefilter=='month') {
            if(this.pxhr)
                this.pxhr.abort()
            this.data.s = {...this.state.filter}
            this.urls = []
            delete this.data.s.departure_datefrom
            delete this.data.s.departure_dateto
            delete this.data.s.flight_number
            delete this.data.s.uld_number
            delete this.data.s.cardit_number
            delete this.data.s.receptacle_id
            if(this.request) {
                this.request.abort()
            }
            this.request = $.ajax({
                isPagination : true,
                url : this.endpoint,
                data : this.data,
                success : response=>{
                    this.setState(state=>{
                        state.data = response.data
                        state.selectedmonth = response.selectedmonth
                        state.currentmonth = response.currentmonth
                        state.nbsacs = response.nbsacs
                        state.total_weight = response.total_weight
                        state.destinations = response.destinations
                        state.piecesbydestinations = response.piecesbydestinations
                        state.weightbydestinations = response.weightbydestinations
                        state.datachart = {
                            labels: response.destinations, 
                            datasets: [
                                {
                                    data: this.state.piecesbydestinations, 
                                    backgroundColor: this.state.backcolors.slice(0, ((response.destinations) ? response.destinations.length : 0)),
                                    weights: response.weightbydestinations
                                }
                            ]
                        }
                        return state
                    })
                    window.setTimeout(this.progress, 100)
                }
            })
        }
    }

    handleOpenClose(id) {
        console.log(id);
        let element = $('#'+id);
        let html = element.html();
        if(html.includes('fa-sort-up'))
        {
            $('#'+id).html('<i class="fa fa-sort-down"></i>');
        } else {
            $('#'+id).html('<i class="fa fa-sort-up"></i>');
        }
    }

    async exportDataSynthese()
    {
        this.setState(state=>{
            state.exportSynthese = 1
            return state
        })
        let filename = "STATS GHA "+moment().format("YYYYMMDDHHmmss")+".xlsx";
        await axios.post('/exportdata', {data: "export synthese"}, { responseType: 'blob' })
        .then((response) => {
            console.log(response);
            let blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel'
            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            this.setState(state=>{
                state.exportSynthese = 0
                return state
            })
            link.click()
        })
        .catch((error) => console.log(error));
    }

    async exportData()
    {
        this.setState(state=>{
            state.exportBrute = 1
            return state
        })
        let filename = "STATS GHA "+moment().format("YYYYMMDDHHmmss")+".xlsx";
        await axios.post('/exportdatabrutes', {data: "export brutes"}, { responseType: 'blob' })
        .then((response) => {
            console.log(response);
            let blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel'
            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            this.setState(state=>{
                state.exportBrute = 0
                return state
            })
            link.click()
        })
        .catch((error) => console.log(error));
    }

    table() {
        return <table className="table table-bordered table-hover table-striped table-liste mail-manifest" cellSpacing="0">
            <thead>
                <tr>
                    <th style={{width: '120px'}}>{trans('Date du vol')}</th>
                    <th style={{width: '120px'}}>{trans('Nº de vol')}</th>
                    {/* <th style={{width: '120px'}}>ULD</th> */}
                    <th style={{width: '98px'}}></th>
                    <th style={{width: '250px'}}>{trans('ULD')}</th>
                    <th style={{width: '140px'}}>{trans('Dest. CARDIT')}</th>
                    <th style={{width: '98px'}}></th>
                    <th style={{width: '250px'}}>{trans('CARDIT')}</th>
                    {/* <th>Nb sacs</th>
                    <th style={{width: '120px'}}>Poids (Kg)</th> */}
                </tr>
            </thead>
            {(this.state.data) ? this.state.data.map((flight, i) => 
                {return flight.cardits.map((cardit, x) =>
                    { 
                        return ((x == 0) ? 
                            <tbody>
                                <tr key={`conveyence-${i}`} className="">
                                    <td className="backfirst">{moment.utc(flight.departure_datetime_lt).local().format('DD/MM/YYYY')}</td>
                                    <td className="bluedark backfirst">{flight.reference}</td>
                                    <td className="backfirst text-center">
                                        <button data-toggle="collapse" data-target={`#details_${i}_${x}`} type="button" aria-expanded="false" aria-controls={`details_${i}_${x}`} className="btn btnAccord" onClick={() => {this.handleOpenClose(`btndetails_uld_${i}_${x}`)}} id={`btndetails_uld_${i}_${x}`} style={{width:'25px'}}>
                                            <i className={`fa fa-sort-down`}></i>
                                        </button>
                                    </td>
                                    <td className="text-blue backfirst text-center">
                                        {flight.uld_number}
                                    </td>
                                    <td className="backfirst">{cardit.arrival_location_iata}</td>
                                    <td className="backfirst text-center">
                                        <button data-toggle="collapse" data-target={`#details_${i}_${x}`} type="button" aria-expanded="false" aria-controls={`details_${i}_${x}`} className="btn btnAccord" onClick={() => {this.handleOpenClose(`btndetails_${i}_${x}`)}} id={`btndetails_${i}_${x}`} style={{width:'25px'}}>
                                            <i className={`fa fa-sort-down`}></i>
                                        </button>
                                    </td>
                                    <td className="backfirst text-center">
                                        {cardit.nsetup.document_number}
                                    </td>
                                    {/* <td className="backfirst greenText">{cardit.sacs}</td>
                                    <td className="backfirst greenText">{numeral(cardit.weight).format('0,0.0')}</td> */}
                                </tr>
                                <tr className="collapse" id={`details_${i}_${x}`}>
                                    <td colspan="2" className="backwhite p-1 mar">
                                    </td>
                                    <td className="p-0" colspan="2">
                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th className="backblue" style={{width:'90px'}}>{trans('Pcs')}</th>
                                                    <th className="backblue">{trans('Kg')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="backwhite">{flight.sacs}</td>
                                                    <td className="backwhite">{numeral(parseFloat(flight.weight)).format('0,0.0')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td></td>
                                    <td className="p-0" colspan="2">
                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th className="backblue" style={{width:'90px'}}>{trans('Pcs')}</th>
                                                    <th className="backblue">{trans('Kg')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="backwhite">{cardit.sacs}</td>
                                                    <td className="backwhite">{numeral(parseFloat(cardit.weight)).format('0,0.0')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        :   <tbody>
                                <tr key={`conveyence-${i}`} className="">
                                    <td className='backsecond p-1'></td>
                                    <td className='backsecond p-1'></td>
                                    <td className='backsecond p-1'></td>
                                    <td className="text-blue backsecond p-1">
                                        <img src={turndown} style={{width:"32px"}} />
                                    </td>
                                    {/* <td className='backsecond p-1'>{cardit.nsetup.document_number}</td> */}
                                    <td className='backsecond p-1'>{cardit.arrival_location_iata}</td>
                                    <td className='backsecond text-center'>
                                        <button data-toggle="collapse" data-target={`#detailscardit_${i}_${x}`} type="button" aria-expanded="false" aria-controls={`detailscardit_${i}_${x}`} className="btn btnAccord" onClick={() => {this.handleOpenClose(`btndetails_${i}_${x}`)}} id={`btndetails_${i}_${x}`} style={{width:'25px'}}>
                                            <i className={`fa fa-sort-down`}></i>
                                        </button>
                                    </td>
                                    <td className="backsecond p-1 text-center">
                                        {cardit.nsetup.document_number}
                                    </td>
                                    {/* <td className='backsecond p-1 greenText'>{cardit.sacs}</td>
                                    <td className='backsecond p-1 greenText'>{numeral(cardit.weight).format('0,0.0')}</td> */}
                                </tr>
                                <tr className="collapse" id={`detailscardit_${i}_${x}`}>
                                    <td colspan="5" className="backwhite p-1 mar">
                                    </td>
                                    <td className="p-0" colspan="2">
                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th className="backblue" style={{width:'90px'}}>{trans('Pcs')}</th>
                                                    <th className="backblue">{trans('Kg')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="backwhite">{cardit.sacs}</td>
                                                    <td className="backwhite">{numeral(parseFloat(cardit.weight)).format('0,0.0')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                )}
            ) : null}
            <tfoot>

            </tfoot>
        </table>
    }

    render() {
        return (
            <div className="cardit-container vol-liste col-md-12">
                <div className="row clearfix align-items-stretch position-relative vol-container">
                    <div className="col-12">
                        <div className="topContainer mb-2 d-flex justify-content-between align-items-center">
                            <div className="col-md-12 mt-2">
                                <div className="align-items-baseline row">
                                    <div className="col-md-3">
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <label className="control-label col-md-3 text-right">Airline</label>
                                            <select className="form-control col-md-9" value={this.state.filter.airline_id} onChange={this.airlineSearch} ref="airline">
                                                <option value="">{trans('Tous')}</option>
                                                {this.props.data.airlines.map(airline=><option key={`select-airline-${airline.id}`} value={airline.id}>{airline.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <label className="control-label col-md-3 text-center p-0">{trans('Origine')}</label>
                                            <select className="form-control col-md-9" value={this.state.filter.departure_location} onChange={this.originSearch} ref="origin">
                                                <option value="">{trans('Tous')}</option>
                                                {this.props.data.select_origins.map(departure_location=><option key={`select-handover-origin-location-${departure_location.iata}`} value={departure_location.iata}>{departure_location.iata}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={`col-md-2`}>
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <label className="control-label col-md-3 text-center p-0 mr-3">Dest.</label>
                                            <select className="form-control col-md-9" value={this.state.filter.arrival_location} onChange={this.destinationSearch} ref="destination">
                                                <option value="">{trans('Tous')}</option>
                                                {this.props.data.select_destinations.map(arrival_location=><option key={`select-handover-destination-location-${arrival_location.iata}`} value={arrival_location.iata}>{arrival_location.iata}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <label className="control-label col-md-3 p-0"></label>
                                            <div className="input-group col-md-9 p-0">
                                                <input ref="uld_number" type="search" placeholder={trans('N° ULD')} value={this.state.filter.uld_number}  className="form-control" onChange={e=>{
                                                                    const value = e.target.value
                                                                    this.setState(state=>{
                                                                        state.filter.uld_number = value
                                                                        return state
                                                                    })
                                                                }}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button" onClick={this.uldSearch}>{trans('OK')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <div className="input-group col-md-11 p-0">
                                                <input ref="cardit_number" type="search" placeholder={trans("N° d'expédition")} value={this.state.filter.cardit_number}  className="form-control" onChange={e=>{
                                                                    const value = e.target.value
                                                                    this.setState(state=>{
                                                                        state.filter.cardit_number = value
                                                                        return state
                                                                    })
                                                                }}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button" onClick={this.carditSearch}>{trans('OK')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div className="align-items-baseline row">
                                    <div className="col-md-3">
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <label className="control-label col-md-3 text-right"></label>
                                            <div className="input-group col-md-9 p-0">
                                                <input ref="flight_number" type="search" placeholder={trans('N° de vol')} value={this.state.filter.flight_number}  className="form-control" onChange={e=>{
                                                                    const value = e.target.value
                                                                    this.setState(state=>{
                                                                        state.filter.flight_number = value
                                                                        return state
                                                                    })
                                                                }}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button" onClick={this.flightSearch}>{trans('OK')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">               
                                        <div className="align-items-baseline d-flex form-group ml-2">      
                                            <label className="fancy-radio m-auto custom-color-green col-md-3 text-center">
                                                <input name={`date-filter`} type="radio" value="month" checked={this.state.datefilter=='month'} onChange={this.handleMonthChecked}/>
                                                <span><i className="mr-0"></i></span>
                                            </label>
                                            <select className="form-control col-md-9" value={this.state.filter.month} onChange={this.handleMonthChange}>
                                                {this.props.data.months.map((month, index)=><option key={index} value={month} defaultValue={this.state.selectedmonth}>{moment.months(index)}</option>)}
                                            </select>
                                        </div>   
                                    </div>
                                    <div className={`col-md-2`}>
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <div className="d-flex col-md-3 p-0 mr-3">
                                                <div className="form-group m-auto">
                                                    <label className="control-label ml-3 mr-2 mb-0">{trans('ou')}</label>
                                                </div>
                                                <label className="fancy-radio m-auto custom-color-green">
                                                    <input name={`date-filter`} type="radio" value="date" checked={this.state.datefilter=='date'} onChange={this.handleDateChecked}/>
                                                    <span><i className="mr-0"></i></span>
                                                </label>
                                            </div>
                                            <div ref="datepicker" className="input-group date col-md-9 p-0">
                                                <input type="text" className="form-control" defaultValue={moment(this.state.filter.departure_datefrom).format("DD/MM/YYYY")}/>
                                                <div className="input-group-append">
                                                    <button className="btn-primary btn text-light" type="button"><i className="fa fa-calendar-alt"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <label className="control-label col-md-3 text-center p-0">{trans('au')}</label>
                                            <div ref="datepicker_to" className="input-group date col-md-9 p-0">
                                                <input type="text" className="form-control" value={moment(this.state.filter.departure_dateto).format("DD/MM/YYYY")} onChange={this.handleDateToChanged}/>
                                                <div className="input-group-append">
                                                    <button className="btn-primary btn text-light" type="button"><i className="fa fa-calendar-alt"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 d-none">
                                        <div className="align-items-baseline d-flex form-group ml-2">
                                            <div className="input-group col-md-11 p-0">
                                                <input ref="receptacle_id" type="search" placeholder={trans('N° de récipient')} value={this.state.filter.receptacle_id}  className="form-control" onChange={e=>{
                                                                    const value = e.target.value
                                                                    this.setState(state=>{
                                                                        state.filter.receptacle_id = value
                                                                        return state
                                                                    })
                                                                }}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button" onClick={this.receptacleSearch}>OK</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card overflowhidden mt-3 container-fluid">
                            <div className="body p-4">
                                <div className="row m-3 align-items-center">
                                    <div className="col-md-5 p-0">
                                        <div className="row m-0 p-0 align-items-center">
                                            {(this.state.datefilter == "month") ? 
                                                <h6 style={{fontWeight: 'bolder', color:'#298094'}} className="text-uppercase mr-3"> {moment.months(this.state.currentmonth)+" "+this.state.selectedyear}</h6> 
                                            : null}
                                            {(this.state.datefilter == "date") ? 
                                                <h6 style={{fontWeight: 'bolder', color:'#298094'}} className="text-uppercase mr-3"> {moment(this.state.filter.departure_datefrom).format("DD/MM/YYYY")} - {moment(this.state.filter.departure_dateto).format("DD/MM/YYYY")}</h6>
                                            : null}

                                            <span style={{color: '#fff', backgroundColor: "#298094", padding: "2px 10px", borderRadius: "2px", marginTop: "-8px"}}>{trans('FLOWN')}</span>

                                            <h6 style={{fontWeight: 'normal'}} className="ml-4">{trans('Nombre de récipients')} : <strong>{numeral(this.state.nbsacs).format('0')} </strong></h6>
                                            <h6 style={{fontWeight: 'normal'}} className="ml-4">{trans('Poids total')} : <strong>{numeral(parseFloat(this.state.total_weight)).format('0.00')} Kg</strong></h6>
                                        </div>                        
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <button className="btn btn-excel" onClick={this.exportData} disabled={(this.state.data.length <= 0) ? true : false}>{(this.state.exportBrute == 0) ? trans('Exporter données brutes') : <i class="fas fa-spinner fa-spin"></i>}</button>
                                        <button className="btn btn-excel ml-2" onClick={this.exportDataSynthese} disabled={(this.state.data.length <= 0) ? true : false}>{(this.state.exportSynthese == 0) ? trans('Exporter synthèse') : <i class="fas fa-spinner fa-spin"></i>}</button>
                                    </div>
                                </div>
                                <div className="row m-0 align-items-top">
                                    <div className="col-md-8">
                                        <div className="card-bureau no-border p-0">
                                            <div className="table-responsive">
                                                {this.table()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center p-4">
                                        { (this.state.datachart.hasOwnProperty('labels') && this.state.datachart.labels != null) ?
                                            <Doughnut data={this.state.datachart} 
                                                options={this.state.optionchart}
                                                height="350"
                                            /> : null
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modelizer(Dashboard);