import React, {Component} from "react";
import $ from "jquery";
import Modelizer from 'ryvendor/Ry/Core/Modelizer';
import numeral from 'numeral';
import './EditableTextField.scss'

class EditableTextField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            text: this.props.value,
        };

        this.inputRef = React.createRef();
        this.prevText = this.props.value;

        this.handleClick = this.handleClick.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isEditing && !prevState.isEditing) {
            this.inputRef.current.focus();
        }
    }

    handleClick() {
        this.setState({ isEditing: true });
    }

    handleBlur () {
        this.setState({ isEditing: false });

        if(this.prevText !== this.state.text){
            $.ajax({
                url : this.props.url,
                type : 'post',
                data : {id: this.props.id, [this.props.dataName]: this.state.text, json:true},
                success : response=>{
                    this.prevText = this.state.text;
                },
                error : response=>{
                    this.setState({ text: this.prevText });
                }
            })
        }
    }

    handleChange(event) {
        this.setState({ text: event.target.value });
    }

    render() {
        return (
            <div className="Editable-text">
                {this.state.isEditing ? (
                    <input
                        ref={this.inputRef}
                        type="text"
                        value={this.state.text}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        placeholder={'Tare'}
                    />
                ) : (
                    <p className="form-control-plaintext py-0"
                       onClick={this.handleClick}>{numeral(parseFloat(this.state.text)).format('0,0.0')} kg</p>
                )}
            </div>
        );
    }
}

export default Modelizer(EditableTextField);