import React, {Component} from 'react';
import trans from '../../translations';
import $ from 'jquery';
import {Popup, PopupHeader, PopupBody} from 'ryvendor/bs/bootstrap';
import Modelizer from 'ryvendor/Ry/Core/Modelizer';
import swal from 'sweetalert2';
import '../CarditExport/List.scss';
import './custom.css';
import moment from 'moment';

class ListManager  extends Component
{
    constructor(props) {
        super(props)
        this.progressive = true
        this.endpoint = '/ulds'
        this.model = 'uld'
        this.nopaginate = true
        this.readOnly = false
        this.state = {
            filter: {
                airline_id : '',
                uld_number : this.models('props.data.filter.uld_number', ''),
                status : this.models('props.data.filter.status', 'circulating'),
                available : this.models('props.data.filter.available', '1')
            },
            uld_number: "",
            status: "circulating",
            airline_id: "",
            poste_id: "",
            data: this.props.data.data.data,
            last_page: this.props.data.data.last_page,
            page: this.props.data.data.current_page ? this.props.data.data.current_page : 1,
            activity: "",
            partyIdentifier: "",
        }

        let criterias = this.models('props.data.filter', {})
        this.data = {
            json : true,
            s : criterias 
        }

        this.table = this.table.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.uldnumberSearch = this.uldnumberSearch.bind(this)

        this.delete = this.delete.bind(this)
        this.update = this.update.bind(this)
        this.changeAvailable = this.changeAvailable.bind(this)
        this.addItem = this.addItem.bind(this)
        this.lendUld = this.lendUld.bind(this)
        this.handlechange = this.handlechange.bind(this)
        this.handlePoste = this.handlePoste.bind(this)
    }

    handlePoste(e, value)
    {
        e.preventDefault();
        let theAmd = this.props.data.amds.find(x => x.id == value);
        this.setState({
            poste_id: theAmd.id,
            partyIdentifier: theAmd.code
        });
    }

    uldnumberSearch() {
        const field = 'uld_number'
        const value = this.refs.uld_number.value
        if(this.pxhr)
            this.pxhr.abort()

        this.setState(state=>{
            state.filter.uld_number = value
            state.data = []
            return state
        })
        this.urls = []
        this.data.s[field] = value
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState({
                    data : response.data.data
                })
                window.setTimeout(this.progress, 100)
            }
        })
    }
    
    onFilter(event, field) {
        const value = event.target.value
        if(this.pxhr)
            this.pxhr.abort()
        this.setState(state=>{
            state.filter[field] = value
            state.data = []
            return state
        })
        this.urls = []
        this.data.s = {...this.state.filter}

        delete this.data.s.uld_number
        this.data.s[field] = value
        if(this.request) {
            this.request.abort()
        }
        this.request = $.ajax({
            isPagination : true,
            url : this.endpoint,
            data : this.data,
            success : response=>{
                this.setState(state=>{
                    state.data = response.data.data
                    return state
                })
                window.setTimeout(()=>{
                  $(".custSelect").selectpicker('refresh')
                }, 0)
                window.setTimeout(this.progress, 100)
            }
        })
    }

    componentDidMount()
    {
        let value = 97;
        let theAmd = this.props.data.amds.find(x => x.id == value);
        this.setState({
            poste_id: theAmd.id,
            partyIdentifier: theAmd.code
        });
        window.setTimeout(()=>{
          $(".custSelect").selectpicker('refresh')
        }, 0);
    }

    delete(e, item) 
    {
        swal({
            title: trans('Confirmez-vous la suppression?'),
            text: trans('Cet enregistrement sera supprimé'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: trans('Oui je confirme')
        }).then((result) => {
            if (result.value) {
                $.ajax({
                    type: 'delete',
                    url : '/ulds',
                    data : {
                        id : item.id
                    },
                    success : ()=>{
                        this.setState(state=>{
                            state.data = state.data.filter(it=>it.id!=item.id)
                            return state
                        })
                    }
                })
                
            }
        })
    }

    changeAvailable(e, item)
    {
        e.preventDefault();
        item.available = (item.available == 0) ? 1 : 0;
        $.ajax({
            type: 'post',
            url : '/ulds',
            data : {
                id : item.id,
                airline_id: item.airline_id,
                status: item.status,
                available: item.available,
                activity: "retour"
            },
            success : (response)=>{
                swal(
                    trans('Enregistré'),
                    trans("Les informations ont bien été enregistrés"),
                    'success'
                )
                this.setState(state=>{
                    state.data = response.data.data
                    return state
                })
                window.setTimeout(()=>{
                  $(".custSelect").selectpicker('refresh')
                }, 0)
            }
        })
    }

    update(e, item)
    {
        e.preventDefault();
        $.ajax({
            type: 'post',
            url : '/ulds',
            data : {
                id : item.id,
                airline_id: item.airline_id,
                status: item.status,
                available: item.available,
                activity: this.state.activity
            },
            success : (response)=>{
                swal(
                    trans('Enregistré'),
                    trans("Les informations ont bien été enregistrés"),
                    'success'
                )
                this.setState(state=>{
                    state.data = response.data.data
                    return state
                })
                window.setTimeout(()=>{
                  $(".custSelect").selectpicker('refresh')
                }, 0)
            }
        })
    }

    addItem(e)
    {
        e.preventDefault();
        $.ajax({
            type: 'post',
            url : '/ulds',
            data : {
                airline_id: this.state.airline_id,
                status: this.state.status,
                uld_number: this.state.uld_number,
            },
            success : (response)=>{
                swal(
                    trans('Enregistré'),
                    trans("Les informations ont bien été enregistrés"),
                    'success'
                )
                this.setState(state=>{
                    state.data = response.data.data
                    return state
                })
                window.setTimeout(()=>{
                  $(".custSelect").selectpicker('refresh')
                }, 0)
            }
        })
    }

    lendUld(e, item)
    {
        e.preventDefault();
        $.ajax({
            type: 'post',
            url : '/lend_ulds',
            data : {
                id: item.id,
                poste_id: this.state.poste_id,
            },
            success : (response)=>{
                swal(
                    trans('Enregistré'),
                    trans("Les informations ont bien été enregistrés"),
                    'success'
                )
                this.setState(state=>{
                    state.data = response.data.data
                    return state
                })
                window.setTimeout(()=>{
                  $(".custSelect").selectpicker('refresh')
                }, 0)
            }
        })
    }

    handlechange(e, item, type, newval)
    {
        e.preventDefault();
        switch(type)
        {
            case "airline_id":
                item.airline_id = newval;
                break;
            case "status":
                item.status = newval;
                break;
        }

        this.update(e, item);
    }

    table() {
        return <table className="table table-bordered table-hover table-striped table-liste table-liste1" cellSpacing="0"
        id="addrowExample">
            <thead>
                <tr>
                    <th>{trans('N° de conteneur')}</th>
                    <th>{trans('GHA')}</th>
                    <th>{trans('Airline')}</th>
                    <th>{trans('Statut')}</th>
                    <th>{trans('Last update')}</th>
                    <th>{trans('En magasin')}</th>
                    <th>{trans('Prêt')}</th>
                    <th>{trans('Historique')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {this.state.data.map((item, itemIndex)=><tr key={`uld-${item.id}`}  className={`bg${itemIndex%2?1:2}`}>
                    <td>
                        <span className="d-inline-block px-2 list-document-number text-blue">{item.uld_number}</span>
                    </td>
                    <td>
                        <span className="d-inline-block px-2 list-document-number text-blue">{item.owner.airport.gha.name}</span>
                    </td>
                    <td width="150px">
                        <select className="custSelect form-control" value={item.airline_id} onChange={(e) => this.handlechange(e, item, 'airline_id', e.target.value)}>
                            <option value="">{trans('Tous')}</option>
                            {this.props.data.airlines.map(airline=><option key={`select-airline-${airline.id}`} value={airline.id}>{airline.iata_code}</option>)}
                        </select>
                    </td>
                    <td width="200px">
                        <select className="custSelect form-control" value={item.status} onChange={(e) => this.handlechange(e, item, "status", e.target.value)}>
                            <option value="">{trans('Tous')}</option>
                            <option value="circulating">{trans('En circulation')}</option>
                            <option value="unavailable">{trans('Indisponible')}</option>
                            <option value="out">{trans('Sortie du stock')}</option>
                        </select>
                    </td>
                    <td className="text-left">
                        <div className="d-inline-block px-2 list-document-number" dangerouslySetInnerHTML={{__html:item.histories[item.histories.length - 1].history}}></div>
                    </td>
                    <td>
                        {(item.available && item.status == "circulating") ?
                            (<a class="align-items-center badge badge-turquoise border-0" href="#" style={{height: "20px", width: "20px"}}><span></span></a>)
                        : (<a class="align-items-center badge badge-white border-0" href="#" style={{height: "20px", width: "20px", backgroundColor: "#fff"}} onClick={(e)=>this.changeAvailable(e, item)}><span></span></a>)}
                    </td>
                    <td>
                        {(item.available) ? (<button className="btn btn-primary" onClick={e=>{ e.preventDefault(); window.setTimeout(()=>{ $(".custSelect").selectpicker('refresh') }, 0); $(`#lenduld-${item.id}`).modal('show') }}>{trans('Prêter')}</button>) : null}

                        <Popup id={`lenduld-${item.id}`} className="modal-md modal-dialog-centered">
                            <PopupBody>
                                <div class="row mt-4">
                                    <div class="col-md-12 text-center">
                                        <h4>{trans('N° ULD')} : <span className="text-blue">{item.uld_number}</span></h4>
                                        <h6 className="mt-2">{trans("A quelle poste souhaitez-vous prêter cet ULD ?")}</h6>
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <select className="custSelect form-control col-md-10" data-live-search="true" value={this.state.poste_id} onChange={e=>this.handlePoste(e, e.target.value)} required>
                                        <option value="" disabled>--- {trans('Choisissez')} ---</option>
                                        {this.props.data.amds.map(amd=><option key={`select-amds-${amd.id}`} value={amd.id}>{amd.name35}</option>)}
                                    </select>
                                    <div class="col-md-12 text-center mt-2">
                                        <span>{trans('Party identifier')} : </span><span className="text-blue">{this.state.partyIdentifier}</span>
                                    </div>
                                </div>
                                <button className="btn btn-primary text-light py-2 mt-3 col-md-6 mb-4" data-dismiss="modal" onClick={e=>this.lendUld(e, item)}>{trans('Valider')}</button>
                            </PopupBody>
                        </Popup>

                    </td>
                    <td>
                        <button class="btn btn-primary2" type="button" onClick={e=>{ e.preventDefault(); $(`#showHistory-${item.id}`).modal('show') }}>{trans('Historique')}</button>
                        
                        <Popup id={`showHistory-${item.id}`} className="modal-xl modal-dialog-centered">
                            <PopupHeader className="pl-3 pb-2" closeButton={<span aria-hidden="true"  className="pb-1 pl-2 pr-2 rounded text-white" style={{background:'#170000'}}>&times;</span>}>
                                <h5>{trans("HISTORIQUE de l'ULD : ")} <span className="text-blue">{item.uld_number}</span></h5>
                            </PopupHeader>
                            <PopupBody>
                                <table className="table table-bordered table-hover table-liste2" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{trans('Date')}</th>
                                            <th>{trans('Statut')}</th>
                                            <th>{trans('Evènement')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {item.histories.map(history=><tr>
                                            <td>{moment.utc(history.created_at).format('DD/MM/YYYY')}</td>
                                            <td>{history.status}</td>
                                            <td><div className="d-inline-block px-2 list-document-number" dangerouslySetInnerHTML={{__html:history.history}}></div></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                                <div className="text-center">
                                    <button className="btn btn-primary text-light py-2 mt-3 col-md-4" data-dismiss="modal">{trans('Fermer')}</button>
                                </div>
                            </PopupBody>
                        </Popup>
                    </td>
                    <td>
                        <button class="btn" type="button" onClick={e=>this.delete(e, item)}><i class="fa fa-2x text-danger fa-trash-alt"></i></button>
                    </td>
                </tr>)}
            </tbody>
            <tfoot className={(this.progressive && this.state.page<this.state.last_page)?'':'d-none'}>
                <tr>
                    <td ref="overscroller" colSpan="13" className={`position-relative py-3`}><i className="spinner"></i></td>
                </tr>
            </tfoot>
        </table>
    }

    render() {
        let pagination = <React.Fragment>
            <a href="#" onClick={this.toFirst} className={this.state.page===1?'disabled':''}><i className="fa fa-angle-double-left"></i></a>
            <a href="#" onClick={this.toPrevious} className={this.state.page===1?'disabled':''}><i className="fa fa-angle-left"></i></a>
            <a href="#" onClick={this.toNext} className={this.state.page===this.state.last_page?'disabled':''}><i className="fa fa-angle-right"></i></a>
            <a href="#" onClick={this.toEnd} className={this.state.page===this.state.last_page?'disabled':''}><i className="fa fa-angle-double-right"></i></a>
        </React.Fragment>

        return <div className="cardit-container vol-liste col-md-12">
            <div className="row clearfix align-items-stretch position-relative vol-container">
                <div className="col-12">
                    <div className="topContainer mb-2 d-flex justify-content-between align-items-center">
                        <div className="col-md-12">
                            <div className="align-items-baseline row">
                                <div className='col-md-3'>
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <label className="control-label mr-2" style={{width: "20%"}}>{trans('Airline')} : </label>
                                        <select className="custSelect form-control" value={this.state.filter.airline_id} onChange={e=>this.onFilter(e, 'airline_id')}>
                                            <option value="">{trans('Tous')}</option>
                                            {this.props.data.airlines.map(airline=><option key={`select-airline-${airline.id}`} value={airline.id}>{airline.iata_code}</option>)}
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-md-2">
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <div className="input-group">
                                            <input ref="uld_number" type="search" placeholder={trans("Nº de conteneur")} value={this.models('state.filter.uld_number', '')} className="form-control" onChange={e=>{
                                                const value = e.target.value
                                                this.setState(state=>{
                                                    state.filter.uld_number = value
                                                    return state
                                                })
                                            }}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={this.uldnumberSearch}>{trans('OK')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='col-md-3'>
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <label className="control-label mr-2" style={{width: "20%"}}>Statut : </label>
                                        <select className="custSelect form-control" value={this.state.filter.status} onChange={e=>this.onFilter(e, 'status')}>
                                            <option value="">{trans('Tous')}</option>
                                            <option value="circulating">{trans('En circulation')}</option>
                                            <option value="unavailable">{trans('Indisponible')}</option>
                                            <option value="out">{trans('Sortie du stock')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="align-items-baseline d-flex form-group ml-2">
                                        <label className="control-label mr-2" style={{width: "32%"}}>{trans('En magasin')} : </label>
                                        <select className="custSelect form-control" value={this.state.filter.available} onChange={e=>this.onFilter(e, 'available')}>
                                            <option value="">{trans('Tous')}</option>
                                            <option value="1">{trans('Oui')}</option>
                                            <option value="0">{trans('Non')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="navPager d-flex align-items-center justify-content-end">
                            {this.nopaginate?null:pagination}
                        </div>
                    </div>
                    
                    <div className="card overflowhidden">
                        <div className="body">
                            <div className="row m-0 justify-content-between">
                                <div className="filter d-flex align-items-center flex-wrap">
                                    <div>
                                        <h6>{trans('Gestion des ULD')}</h6>
                                    </div>
                                    {this.nopaginate?null:<div className="form-group d-flex align-items-center justify-content-start flex-nowrap" style={{width:220}}>
                                        <label className="control-label">{trans('Voir')}</label>
                                        <select className="custSelect form-control" value={this.models('state.filter.perpage', 25)} onChange={e=>this.onFilter(e, 'perpage')}>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        <label>{trans('par page')}</label>
                                    </div>}
                                </div>
                            </div>
                            <div className="card-bureau no-border p-0">
                                <div className="table-responsive">
                                    {this.table()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="navPager d-flex align-items-center justify-content-end">
                            {this.nopaginate?null:pagination}
                        </div>
                    </div>
                </div>
            </div>

            <Popup id={`adduld`} className="modal-lg modal-dialog-centered">
                <PopupBody>
                        <div className="form-group">
                            <label className="control-label">
                                {trans('Nº de conteneur')}
                            </label>
                            <input type="text" className="form-control bs-default" name="uld_number" onChange={(e)=>this.setState({uld_number: e.target.value})} required/>
                        </div>
                        <div className="form-group">
                            <label className="control-label mr-2">{trans('Airline')}</label>
                            <select className="custSelect form-control" name="airline_id" onChange={e=>this.setState({airline_id: e.target.value})}>
                                <option value="">{trans('Tous')}</option>
                                {this.props.data.airlines.map(airline=><option key={`select-airline-${airline.id}`} value={airline.id}>{airline.iata_code}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="control-label mr-2">Statut</label>
                            <select className="custSelect form-control" name="status" onChange={e=>this.setState({status: e.target.value})} required>
                                <option value="">--- {trans('Choisissez')} ---</option>
                                <option value="circulating" selected>{trans('En circulation')}</option>
                                <option value="unavailable">{trans('Indisponible')}</option>
                                <option value="out">{trans('Sortie du stock')}</option>
                            </select>
                        </div>
                        <button className="btn btn-primary text-light py-2 mt-3" data-dismiss="modal" onClick={(e)=>this.addItem(e)}>{trans('Ajouter un ULD')}</button>
                </PopupBody>
            </Popup>

        </div>

        
    }



}

export default Modelizer(ListManager)